.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid gray;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #0078d7;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

.react-daterange-picker__wrapper {
  border: none;
}
.react-daterange-picker {
  width: 100%;
  height: 100%;
}

.react-daterange-picker__calendar {
  top: calc(100% + 8px);
}

.react-daterange-picker__inputGroup {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.2;
}
.react-daterange-picker__inputGroup input {
  outline: none;
  color: #3c3e45;
}
.react-daterange-picker__range-divider span {
  border-left: 1px solid #d0d1d8;
  height: 20px;
}
.react-daterange-picker__inputGroup:first-child {
  padding-right: 33px;
}
.react-daterange-picker__inputGroup:not(:first-child) {
  padding-left: 33px;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: inherit;
}
.react-daterange-picker__inputGroup__leadingZero {
  color: inherit;
  display: inline-block;
  height: 100%;
}

.react-select-container .react-select__menu {
  border: 1px solid #eeeef1;
  color: #898d9e;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  top: calc(100% + 8px);
}

.react-select-container .react-select__control {
  margin-top: 8px;
  background-color: #eeeef1;
  border-radius: 8px;
  color: #3c3e45;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 17px;
  border: 1px solid #eeeef1;
  box-shadow: none;
  padding-left: 24px;
  padding-right: 16px;
}

.react-select__control:hover {
  border: 1px solid #232d5c !important;
  box-shadow: none;
}

.react-select__control .react-select__value-container {
  padding-left: 0;
}

.react-select__value-container .react-select__placeholder {
  color: #898d9e;
}

.react-select__menu-list .react-select__option {
  padding: 12px 24px;
  background-color: inherit;
  cursor: pointer;
  color: #898d9e;
}

.react-select__menu-list .react-select__option:hover {
  background-color: #eeeef1;
  color: #232d5c;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__indicators .react-select__indicator {
  color: #898d9e;
}
